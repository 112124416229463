import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/components/dashboard/Dashboard'
import Bookings from '@/components/bookings/Bookings'
import Classes from '@/components/classes/Classes'
import Cooks from '@/components/cooks/Cooks'
import Locations from '@/components/locations/Locations'
import Coupons from '@/components/coupons/Coupons'
import Frontend from '@/components/frontend/Frontend'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/buchungen',
      name: 'Buchungen',
      component: Bookings
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/buchungen',
      name: 'Buchungen',
      component: Bookings
    },
    {
      path: '/kurse',
      name: 'Kurse',
      component: Classes
    },
    {
      path: '/koeche',
      name: 'Köche',
      component: Cooks
    },
    {
      path: '/orte',
      name: 'Orte',
      component: Locations
    },
    {
      path: '/gutscheine',
      name: 'Gutscheine',
      component: Coupons
    },
    {
      path: '/buchungsformular',
      name: 'Buchungsformular',
      component: Frontend
    }
  ]
})
