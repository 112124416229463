<template>
  <mdb-container class="p-4" fluid>
    <!--Section: Overview-->
    <section>
      <mdb-row>
        <mdb-col lg="6" xl="3" class="mb-4">
          <mdb-card narrow>
            <mdb-view cascade gradient="dusty-grass">
              Statistik für {{ date.month }} {{ date.year }}
            </mdb-view>
            <mdb-card-body cascade>
              <month-picker
                @change="showDate"
                lang="de"
                default-month="8"
              >
              </month-picker>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="6" xl="9" class="mb-4">
          <mdb-card narrow>
            <mdb-view cascade gradient="dusty-grass">
              Tagesumsatz
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-line-chart
                :data="lineChartData"
                :options="lineChartOptions"
                :width="800"
                :height="267"
                :responsive="true"
                :maintainAspectRatio="false"
              ></mdb-line-chart>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <!--/.Section: Overview-->
    <!--Section: Progress-->
    <section>
      <mdb-row>
        <!--Card: Invitation-->
        <mdb-col xl="4" md="12" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="chair" fas class="gww-blue"></mdb-icon>
              <div class="data">
                <p>AUSLASTUNG</p>
                <h4 class="font-weight-bold dark-grey-text">659 / 1040</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value="63" color="gww-blue" />
              <mdb-card-text>659 von 1040 Plätzen belegt (<span class="font-weight-bold">63%</span>)</mdb-card-text>
              <mdb-stretched-link href="./statistic" tag="router-link" class="stretched-link">Go somewhere</mdb-stretched-link>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Invitation-->
        <!--Card: Ticket-->
        <mdb-col xl="4" md="12" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="exclamation-triangle" fas class="gww-red"></mdb-icon>
              <div class="data">
                <p>OFFENE POSTEN</p>
                <h4 class="font-weight-bold dark-grey-text">120</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value="18" color="gww-red" />
              <mdb-card-text class="card-text">120 von 659 Plätzen unbezahlt (<span class="font-weight-bold">18%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Ticket-->
        <!--Card: Visitor-->
        <mdb-col xl="4" md="12" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="coins" fas class="gww-blue"></mdb-icon>
              <div class="data">
                <p>MONATSUMSATZ</p>
                <h4 class="font-weight-bold dark-grey-text">72.584,39 €</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value="78" color="gww-blue" />
              <mdb-card-text>72.584,39 € von 92.883,78 € bezahlt (<span class="font-weight-bold">78%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Visitor-->
      </mdb-row>
    </section>
    <!--/.Section: Progress-->
    <!--Section: Log-->
    <section>
      <mdb-row>
        <!--Card: History-->
        <mdb-col xl="12" class="mb-4">
          <mdb-card>
            <mdb-card-header color="gww-blue" class="white-text">
              <mdb-icon icon="history" class="mr-1" /> Letzte Buchungen
            </mdb-card-header>
            <mdb-card-body class="pt-0 px-1">
              <mdb-card-body class="text-center">
                <mdb-list-group>
                  <mdb-list-group-item tag="a" :action="true" class="d-flex justify-content-between dark-grey-text">
                    <small>12.08.2021 06:00<br><b>Trüffel - Das Menü</b></small>
                    <span class="flex-grow-1 text-left pl-4 pr-3"><b>1 Platz</b> gebucht von Max Mustermann</span>
                    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                      <div class="tooltip">Klicken um die Nachricht zu öffnen</div>
                      <mdb-icon slot="reference" icon="chevron-right" class="ml-auto"/>
                    </mdb-tooltip>
                  </mdb-list-group-item>
                  <mdb-list-group-item tag="a" :action="true" class="d-flex justify-content-between dark-grey-text">
                    <small>11.08.2021 05:00<br><b>Trüffel - Das Menü</b></small>
                    <span class="flex-grow-1 text-left pl-4 pr-3"><b>1 Platz</b> gebucht von Max Mustermann</span>
                    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                      <div class="tooltip">Klicken um die Nachricht zu öffnen</div>
                      <mdb-icon slot="reference" icon="chevron-right" class="ml-auto"/>
                    </mdb-tooltip>
                  </mdb-list-group-item>
                  <mdb-list-group-item tag="a" :action="true" class="d-flex justify-content-between dark-grey-text">
                    <small>10.08.2021 04:00<br><b>Trüffel - Das Menü</b></small>
                    <span class="flex-grow-1 text-left pl-4 pr-3"><b>2 Plätze</b> gebucht von Max Mustermann</span>
                    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                      <div class="tooltip">Klicken um die Nachricht zu öffnen</div>
                      <mdb-icon slot="reference" icon="chevron-right" class="ml-auto"/>
                    </mdb-tooltip>
                  </mdb-list-group-item>
                </mdb-list-group>
              </mdb-card-body>
            </mdb-card-body>
            <mdb-card-footer class="text-muted">
              <a href="./#/buchungen"><mdb-btn size="sm" color="">Alle Buchungen anzeigen <mdb-icon icon="chevron-right" class="ml-1"/></mdb-btn></a>
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: History-->
      </mdb-row>
    </section>
    <!--/.Section: Log-->
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCard, mdbCardBody, mdbCardFooter, mdbCardHeader, mdbCardText, mdbCol, mdbBtn, mdbTooltip, mdbIcon, mdbView, mdbListGroup, mdbListGroupItem, mdbStretchedLink, mdbProgress, mdbLineChart } from 'mdbvue'
import { MonthPicker } from 'vue-month-picker'

// Vue.use(MonthPicker)
// Vue.use(MonthPickerInput)

// Variables
// var gwwDarkGrey = '#747373'
// var gwwGreen = '#94c11a'

export default {
  name: 'Dashboard',
  components: {
    mdbContainer,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbCardFooter,
    mdbCardHeader,
    mdbCardText,
    mdbCol,
    mdbBtn,
    mdbTooltip,
    mdbIcon,
    mdbView,
    mdbListGroup,
    mdbListGroupItem,
    mdbStretchedLink,
    mdbProgress,
    mdbLineChart,
    MonthPicker
  },
  data () {
    return {
      date: {
        from: null,
        to: null,
        month: null,
        year: null
      },
      lineChartData: {
        labels: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31'
        ],
        datasets: [
          {
            label: 'Umsatz',
            backgroundColor: 'rgba(121, 181, 28, 0.1)',
            borderColor: 'rgba(121, 181, 28, 1)',
            borderWidth: 0.7,
            data: [300, 450, 550, 89, 112, 99, 560, 100, 250, 256, 333, 720, 300, 100, 50, 704, 403, 102, 200, 128, 540, 390, 490, 40, 124, 200, 320, 409, 489, 548, 602]
          }
        ]
      },
      lineChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    showDate (date) {
      this.date = date
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .month-picker__container {
    width: 100% !important;
  }
  #line-chart {
    width: 100% !important;
    max-width: 800px;
    height: auto !important;
  }
  .dusty-grass-gradient {
    background-image: linear-gradient(120deg, #79b51c 0%, #79b51c 100%) !important;
  }
  .month-picker__month.selected,
  .month-picker__month.selected-range-first,
  .month-picker__month.selected-range-second {
    background-color: #79b51c !important;
  }
</style>
