<template>
  <div class="white-skin mdb-admin-sidenav">
    <mdb-side-nav 
      logo="logo.png"
      sideNavClass="sn-bg-1"
      mask="strong"
      :OpenedFromOutside.sync="toggle"
    >
      <li>
        <mdb-side-nav-nav>
          <!--<mdb-side-nav-item header @click.native="toggle=false" icon="chart-line" to="/dashboard" class="text-uppercase text-bigger">Dashboard</mdb-side-nav-item>-->
          <mdb-side-nav-item header @click.native="toggle=false" icon="list-ul" to="/buchungen" class="text-uppercase text-bigger">Buchungen</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="calendar-alt" to="/kurse" class="text-uppercase text-bigger">Kurse</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="tag" fas to="/gutscheine" class="text-uppercase text-bigger">Gutscheine</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="utensils" fas to="/koeche" class="text-uppercase text-bigger">Köche</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="map-marker-alt" fas to="/orte" class="text-uppercase text-bigger">Orte</mdb-side-nav-item>
          <hr>
          <mdb-side-nav-item header @click.native="toggle=false" icon="file-alt" to="/buchungsformular" class="text-uppercase text-bigger">Buchungsformular</mdb-side-nav-item>
        </mdb-side-nav-nav>
      </li>
    </mdb-side-nav>
  </div>
</template>

<script>
import {
  mdbSideNav,
  mdbSideNavNav,
  mdbSideNavItem,
  waves
} from "mdbvue"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "SideNav",
  props: {
    waves: {
      type: Boolean,
      default: true
    },
    open: {
      type: Boolean
    }
  },
  components: {
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavItem
  },
  data() {
    return {
      active: 0,
      elHeight: 0,
      windowHeight: 0,
      toggle: true,
      width: 0
    }
  },
  computed: mapGetters(["sidenav"]),
  methods: {
    ...mapActions(["toggleSideNav"]),
    afterEnter(el) {
      el.style.maxHeight = "1000px"
    },
    beforeLeave(el) {
      el.style.maxHeight = 0
    },
    setWidth() {
      this.width = window.innerWidth
    }
  },
  mounted() {
    this.setWidth()
    window.addEventListener("resize", this.setWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth)
  },
  watch: {
    toggle(value) {
      this.toggleSideNav(value)
    },
    sidenav(value) {
      this.toggle = value
    },
    width(value) {
      if (value < 1440) {
        this.toggle = false
      } else this.toggle = true
    }
  },
  mixins: [waves]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>

.side-nav {
  opacity: 1 !important;
  transition: transform 0.3s linear !important;
  background-color: rgb(227, 228, 229) !important;
  background-image: url('~@/assets/images/sidenav.jpg') !important;
}

.logo-wrapper {
  height: 216px !important;
  padding-bottom: 0px !important;
}

.logo-wrapper img {
  padding: 0px !important;
}

.text-bigger a {
  font-size: 17px !important;
  font-weight: 300 !important;
}

.text-bigger i {
  font-size: 17px !important;
  color: #79b51c;
}


</style>
